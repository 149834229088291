import { useState } from "react";
import Post from "infortech_modules/Crud/Post";
import EsVacio from "infortech_modules/Constantes/EsVacio";
import EsNumero from "infortech_modules/Constantes/EsNumero";
import Formularios from "infortech_modules/Componentes/Formularios";
import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import Modales from "infortech_modules/Componentes/Modales";
import Boton from "infortech_modules/Componentes/Boton";
import Upload from "infortech_modules/Crud/Upload";
import { IoMdAddCircleOutline } from 'react-icons/io';


export default function ModalAdd({
    tipoBotonPrincipal, IconoBotonPrincipal, SpinnerBotonPrincipal, claseIconBotonPrincipal, tituloBotonPrincipal, widthBotonPrincipal,
    tipoBotonSecundario, IconoBotonSecundario, SpinnerBotonSecundario, claseIconBotonSecundario, tituloBotonSecundario, widthBotonSecundario,
    tipoBotonEnviar, IconoBotonEnviar, SpinnerBotonEnviar, claseIconBotonEnviar, tituloBotonEnviar, widthBotonEnviar,
    modalSize, modalTitulo, modalCentrado, contenidoSuperiorModal, formulario, onChange, contenidoInferiorModal,
    comprobarTextoVacio, mensajeTextoVacio, numeroComprobar, mensajeNumeroComprobar, mensajeLoadingPromesa, mensajePromesa,
    nombreArchivo, archivo, rutaArchivo, recargar, principal, tipoMensajePromesa, tiempoMensajePromesa, ejecutar, iniciar, agregar, datos1, datos2, datos3,
    datos4, datos5, datos6, valorConsultarHistorial, tabla, url, duplicados, caso, key1, small,
}) {
    tipoBotonPrincipal = tipoBotonPrincipal === undefined ? 'icono' : tipoBotonPrincipal;
    IconoBotonPrincipal = IconoBotonPrincipal === undefined ? IoMdAddCircleOutline : IconoBotonPrincipal;
    tituloBotonPrincipal = tituloBotonPrincipal === undefined ? 'Agregar' : tituloBotonPrincipal;
    claseIconBotonPrincipal = claseIconBotonPrincipal === undefined ? "fs-3 pointer text-blue-700" : claseIconBotonPrincipal;

    tipoBotonSecundario = tipoBotonSecundario === undefined ? 'secondary' : tipoBotonSecundario;
    tituloBotonSecundario = tituloBotonSecundario === undefined ? 'Cerrar' : tituloBotonSecundario;

    tipoBotonEnviar = tipoBotonEnviar === undefined ? 'primary' : tipoBotonEnviar;
    tituloBotonEnviar = tituloBotonEnviar === undefined ? 'Agregar' : tituloBotonEnviar;

    small = small === undefined ? true : small;
    recargar = recargar === undefined ? false:recargar;



    const [modal, setModal] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const open = () => {
        setModal(true);
        if (ejecutar !== undefined) {
            ejecutar();
        }
    }
    const cerrar = () => setModal(false);

    const procesar = () => {
        setDisabled(true);
        function activarBoton(t) {
            setTimeout(() => {
                setDisabled(false);
            }, t);
        }

        const enviarFile = () => {
            if (archivo !== undefined) {
                const promesa = new Promise((res, rej) => {
                    Upload(nombreArchivo, archivo, rutaArchivo).then((u) => {
                        agregar.img = u;
                        Post(({
                            table: tabla, url: url, duplicate: duplicados, caso: caso, data0: agregar, data1: datos1, data2: datos2, data3: datos3, data4: datos4, data5: datos5, data6: datos6,
                            mensaje: mensajePromesa, reload: recargar, value: valorConsultarHistorial, key: key1, iniciar: iniciar
                        }))
                            .then((r) => {
                                mensajePromesa !== '' && setTimeout(() => {
                                    res(mensajePromesa)
                                }, 500);
                                if (!recargar) {
                                    setTimeout(() => {
                                        setModal(false);
                                        principal(true);
                                    }, 2000);
                                } else {
                                    setTimeout(() => {
                                        setModal(false);
                                        setDisabled(false);
                                    }, 500);
                                }
                            }).catch((f) => {
                                setTimeout(() => {
                                    rej(f)
                                    setDisabled(false);
                                }, 500);
                            })
                    }).catch((f) => {
                        setTimeout(() => {
                            rej(f)
                            setDisabled(false);
                        }, 500);
                    })

                });
                AlertaPromesa(mensajeLoadingPromesa, promesa, tipoMensajePromesa, tiempoMensajePromesa);

            } else {
                enviar();
            }
        }

        const enviar = () => {
            const promesa = new Promise((res, rej) => {
                Post(({
                    table: tabla, url: url, duplicate: duplicados, caso: caso, data0: agregar, data1: datos1, data2: datos2, data3: datos3, data4: datos4, data5: datos5, data6: datos6,
                    mensaje: mensajePromesa, reload: recargar, value: valorConsultarHistorial, key: key1, iniciar: iniciar
                }))
                    .then((r) => {
                        mensajePromesa !== '' && setTimeout(() => {
                            res(mensajePromesa)
                        }, 500);
                        if (!recargar) {
                            setTimeout(() => {
                                setModal(false);
                                principal(true);
                            }, 2000);
                        } else {
                            setTimeout(() => {
                                setModal(false);
                                setDisabled(false);
                            }, 500);
                        }
                    }).catch((f) => {
                        setTimeout(() => {
                            rej(f)
                            setDisabled(false);
                        }, 500);
                    })
            })
            AlertaPromesa(mensajeLoadingPromesa, promesa, tipoMensajePromesa, tiempoMensajePromesa);
        }

        if (EsVacio(comprobarTextoVacio, mensajeTextoVacio)) {
            if (numeroComprobar !== undefined && mensajeNumeroComprobar !== undefined) {
                if (EsNumero(numeroComprobar, mensajeNumeroComprobar)) {
                    enviarFile();
                } else {
                    activarBoton(1500)
                }
            } else {
                enviarFile();
            }
        } else {
            activarBoton(1500)
        }
    }


    return (
        <>
            {
                <Boton tipo={tipoBotonPrincipal} Icono={IconoBotonPrincipal} SpinnerBoton={SpinnerBotonPrincipal}
                    claseIcon={claseIconBotonPrincipal} onClick={() => open()} titulo={tituloBotonPrincipal}
                    width={widthBotonPrincipal}
                />
            }

            <Modales
                show={modal}
                onHide={cerrar}
                size={modalSize}
                titulo={modalTitulo}
                centered={modalCentrado}
                body={
                    <>
                        {contenidoSuperiorModal !== undefined && contenidoSuperiorModal}
                        <div className={small && 'scaled'}>
                            <Formularios datos={formulario} onChange={onChange} />
                        </div>

                        {contenidoInferiorModal !== undefined && contenidoInferiorModal}

                    </>
                }
                footer={
                    <>

                        <Boton tipo={tipoBotonSecundario} Icono={IconoBotonSecundario} SpinnerBoton={SpinnerBotonSecundario}
                            claseIcon={claseIconBotonSecundario} onClick={() => cerrar()} titulo={tituloBotonSecundario}
                            width={widthBotonSecundario}
                        />
                        <Boton tipo={tipoBotonEnviar} Icono={IconoBotonEnviar} SpinnerBoton={SpinnerBotonEnviar}
                            claseIcon={claseIconBotonEnviar} disabled={disabled} onClick={() => procesar()} titulo={tituloBotonEnviar}
                            width={widthBotonEnviar}
                        />


                    </>
                }
            />

        </>
    )
};
