import { useState, useEffect } from "react";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Get from "infortech_modules/Crud/Get";
import Permisos, {  usuario } from "infortech_modules/Constantes/Usuarios";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import Formularios from "infortech_modules/Componentes/Formularios";
import { cambiarFormulario, imagenes } from "infortech_modules/Constantes/Constantes";
import Modales from "infortech_modules/Componentes/Modales";
import Boton from "infortech_modules/Componentes/Boton";
import { BiEditAlt, BiImageAdd } from "react-icons/bi";
import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import Put from "infortech_modules/Crud/Put";
import EsVacio from "infortech_modules/Constantes/EsVacio";
import Upload from "infortech_modules/Crud/Upload";

export default function FotoPortada({ iniciar }) {
    useEffect(() => {
        principal();
        // eslint-disable-next-line
    }, [])
    const [cargar, setCagar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('')
    const [editar, setEditar] = useState({
        nit: "", nom: "", dir: "", tel: "", ciu: "", dep: "", pais: "", lat: "", lon: "", link: "", logo: "", fot: "", des: ""
    })
    const [img, setImg] = useState(undefined);
    const [modal, setModal] = useState(false);
    const [disbaledBoton, setDisabledBoton] = useState(false);


    const open = () => {
        setModal(true);
    }

    const closed = () => {
        setModal(false);
    }

    const principal = (est) => {
        est && setCagar(true);
        Get({ url: '/get', key: 6, id: usuario().id }, iniciar = { iniciar }).then((r) => {
            setTimeout(() => {
                setEditar(r[0]);
                setCagar(false)
            }, 500);
        }).catch((f) => {
            setError(true);
            setMensaje(f)
            setTimeout(() => {
                setCagar(false)
            }, 500);
        })
    }


    const formulario = [
        [CrearInput({ type: 'file', name: "logo", placeholder: 'Imagen de Portada' })]
    ]


    const cambiar = (e) => {
        cambiarFormulario(e, editar, setEditar, setImg)
    }

    const guardar = () => {
        setDisabledBoton(true)
        if (EsVacio([img !== undefined ? "ok" : ""], [
            "No ha cargado una imagen para guardar"
        ])) {
            const promesa = new Promise((res, rej) => {

                Upload('photo', img, '/Upload/pportada.php').then((u) => {
                    const enviar = { por: u };

                    Put({
                        url: '/put', data0: enviar, id: `id = ${usuario().id}`, caso: 'tarjetaPortadaEdit', table: 'usuarios',
                        
                    }).then((r) => {
                        setTimeout(() => {
                            setImg(undefined);
                            principal();
                            setDisabledBoton(false);                           
                            closed();
                            res(editar.por !== "" && editar.por !== undefined ?  "Su Imagen de Portada ha sido Actualizada":"Se guardo su imagen de portada");
                        }, 500);

                    }).catch((f) => {
                        setTimeout(() => {
                            rej(f);
                            setDisabledBoton(false)
                        }, 500);
                    })

                }).catch((f) => {
                    rej(f);
                    setDisabledBoton(false)
                }, 500);
            })

            AlertaPromesa('Actualizando Imagen de Portada', promesa, 'a');
        } else {
            setTimeout(() => {
                setDisabledBoton(false)
            }, 1000);
        }

    }

   

    return (
        <>

            <Contenedor
                titulo={'Imagen de Portada'} rutas={['Inicio', 'Mi Tarjeta', 'Foto de Portada']} autorizado={Permisos().tarjetaPortada}
                cargar={cargar} mensajeCarga={'Obteniendo datos...'} altura={"85vh"} anchoImagen={50} spiner={false}
                error={error} mensajeError={mensaje} colorIconError={'white'} claseNameTextError={'text-xl font-bold'}
                header={<>

                    {
                        editar.por !== "" && editar.por !== undefined ?                            <>
                                <div className="h3 text-center p-3"> &nbsp;
                                    <div className='infoText'>
                                        <BiEditAlt
                                            className={"fs-2 pointer text-blue-700"}
                                            onClick={() => open()}
                                        />
                                        <span className='textText-Top'>{'Cambiar Foto de Portada'}</span>
                                    </div>
                                </div>
                                <div className="l-logo-c">
                                    <div className="l-logo" style={{ backgroundImage: `url(${imagenes.pportada + editar.por})` }}></div>
                                </div>

                            </> :
                            <div className="mb-3">
                                No ha establecido una foto de Portada
                                <div className="h3 text-center p-3"> &nbsp;
                                    <div className='infoText'>
                                        <BiImageAdd
                                            className={"fs-1 pointer text-blue-700"}
                                            onClick={() => open()}
                                        />
                                        <span className='textText-Top'>{'Establecer Foto de Portada'}</span>
                                    </div>
                                </div>
                            </div>
                    }
                </>}


            />
            <Modales
                show={modal}
                onHide={closed}
                titulo={editar.por !== "" && editar.por !== undefined ?  "Cambiar Imagen de Portada":"Establecer Imagen de Portada"}
                centered={true}
                body={<>{
                    <Formularios datos={formulario} onChange={cambiar} />
                }
                </>}

                footer={<>
                    <Boton tipo={'secondary'} titulo={'Cerrar'} onClick={() => closed()} />
                    <Boton tipo={'primary'} disabled={disbaledBoton} titulo={editar.por !== "" && editar.por !== undefined ?  "Cambiar":"Guardar"} onClick={() => guardar()} />

                </>}

            />

        </>
    )
};
