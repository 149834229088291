
import Contenedor from "infortech_modules/Componentes/Contenedor";
import { upload } from "infortech_modules/Constantes/Constantes";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import Permisos, {  usuario } from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import { useEffect, useState } from "react";


export default function Dashboard({iniciar}) {
    const datos = [];
    const [cargar, setCagar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('')
    const [data, setData] = useState([]);




    useEffect(() => {
        principal(); 
        // eslint-disable-next-line       
    },[])

    const principal = (est) => {
        est && setCagar(true);
        Get({url:'/get/general', key:'usuarios', data0:usuario().emp, data1:usuario().rol, data2:usuario().id}, iniciar={iniciar}).then((r) =>{           
            setTimeout(() => {
                setData(r[0]);              
                setCagar(false);

            }, 500);
        }).catch((f) => {
            setMensaje(f);
            setTimeout(() => {
                setCagar(false);
                setError(true);                
            }, 500);
        })              
    }

  
    const row = data.map((v,i) => {
        datos[i] = {wa:v.wa, fb:v.fb,ig:v.ig,tw:v.tw, yt:v.yt}
        return (
            <tr key={i}>
                {
                    CreaarFila([i+1, v.nom + ' '+ v.ape, v.cargo, <img src={`${upload}/Server/generarqr.php?url=${upload}/index.php?perfil=${v.url}`} alt="Codigo QR del Usuario" /> , <a target={"_blank"} rel="noreferrer" href={`${upload}/index.php?perfil=${v.url}`}>{`${upload}/index.php?perfil=${v.url}`}</a>], "scope", [""], "text-center")                    
                }
              
            </tr>
        )
    })

    return (
        <>
        <Contenedor
        rutas={['Inicio', 'Usuarios']}
        cargar={cargar} error={error} mensajeError={mensaje} mensajeCarga={'Obteniendo Información de Usuarios'} colorIconError={'white'}
        altura={'85vh'} classNameMensaje={'fs-5'} spiner={false} anchoImagen={50} claseNameTextError={'p-2 text-white fs-5'} autorizado={Permisos().usuarios}
        titulo={'Usuarios'}
        tablaEncabezado={['No.', "Usuario", "Cargo", "QR", "Acceso a Tarjeta Virtual"]}
        tablaNombre={'usr'} claseTablaEncabezado={'text-center'} tablaFilas={row}
        />
        </>
    )
};
