import { useState, useEffect } from "react";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Get from "infortech_modules/Crud/Get";
import Permisos, { Autorizado, usuario } from "infortech_modules/Constantes/Usuarios";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import Formularios from "infortech_modules/Componentes/Formularios";
import { cambiarFormulario, duplicate } from "infortech_modules/Constantes/Constantes";
import Mapa from "infortech_modules/Componentes/Mapa";
import Modales from "infortech_modules/Componentes/Modales";
import Boton from "infortech_modules/Componentes/Boton";
import { urlMaps } from "infortech_modules/Constantes/Constantes";
import Put from "infortech_modules/Crud/Put";
import EsVacio from "infortech_modules/Constantes/EsVacio";
import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";

export default function DatosBasicos({ iniciar }) {
    useEffect(() => {
        principal();
        // eslint-disable-next-line
    }, [])
    const [cargar, setCagar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('')
    const [editar, setEditar] = useState({
        id: "", nit: "", nom: "", dir: "", tel: "", ciu: "", dep: "", pais: "", lat: "", lon: "", link: "", logo: "", fot: "", des: "", usr: "", web:'',
    })
    //const [img, setImg] = useState(undefined);
    const [disabled, setDisabled] = useState(true);
    const [modal, setModal] = useState(false);
    const [coor, setCoor] = useState({ lon: undefined, lat: undefined });
    const [disbaledBoton, setDisabledBoton] = useState(false);



    const closed = () => {
        setModal(false);
    }

    const principal = () => {
        Get({ url: '/get', key: 3, id: usuario().emp, iniciar:iniciar }).then((r) => {
            setTimeout(() => {
                setEditar(r[0]);
                setCagar(false)
            }, 500);
        }).catch((f) => {
            setError(true);
            setMensaje(f)
            setTimeout(() => {
                setCagar(false)
            }, 500);
        })
    }



    const formulario = [
        [CrearInput({ type: 'text', name: "nit", value: editar.nit, placeholder: "NIT No.", disabled: true, clase: 'col-xl-3 col-sm-3 col-lg-3 col-12' }), CrearInput({ type: 'text', name: 'nom', value: editar.nom, placeholder: "Nombre Empresa", disabled: true, clase: 'col-xl-9 col-sm-9 col-lg-9 col-12' })],
        [CrearInput({ type: 'text', name: 'dir', value: editar.dir, placeholder: 'Dirección', disabled: disabled, clase: 'col-xl-7 col-sm-7 col-lg-7 col-12' }), CrearInput({ type: 'text', name: 'tel', value: editar.tel, placeholder: "Teléfono", disabled: disabled, clase: 'col-xl-5 col-sm-5 col-lg-5 col-12' })],
        [CrearInput({ type: 'text', name: 'ciu', value: editar.ciu, placeholder: 'Ciudad', disabled: disabled, clase: 'col-xl-4 col-sm-4 col-lg-4 col-12' }), CrearInput({ type: 'text', name: 'dep', value: editar.dep, placeholder: "Departamento", disabled: disabled, clase: 'col-xl-4 col-sm-4 col-lg-4 col-12' }),
        CrearInput({ type: 'text', name: 'pais', value: editar.pais, placeholder: 'País', disabled: disabled, clase: 'col-xl-4 col-sm-4 col-lg-4 col-12' }),
        ],
        [CrearInput({type:'text', name:'web', value:editar.web, placeholder:'Página Web', disabled:disabled})],
        [CrearInput({ type: 'text-area', name: 'des', value: editar.des, placeholder: "Información General de la Empresa", disabled: disabled })],
    ]


    const cambiar = (e) => {
        cambiarFormulario(e, editar, setEditar)
    }

    const enviarCoordenadas = (coordenadas) => {
        setCoor({ lat: coordenadas.lat, lon: coordenadas.lng });
        setEditar((v) => {
            v.lat = coordenadas.lat;
            v.lon = coordenadas.lng;
            v.link = urlMaps(coordenadas, 16)
            return v;
        })
        setModal(false);
    }

    const guardar = () => {
        setDisabledBoton(true)
        if (EsVacio([editar.dir, editar.tel, editar.ciu, editar.dep, editar.pais, editar.des], [
            "La dirección de la empresa no puede ser vacío", "Digite el número de teléfono de la compañia", "Digite la ciudad",
            "Digita el departamento", "Digite el País", "Debe proporcionar información sobre la compañia"
        ])) {
            const promesa = new Promise((res, rej) => {
                Put({
                    url: '/put', data0: editar, id: `id = ${editar.id}`, caso: 'empresaEdit', table: 'empresas',
                    duplicate: duplicate(false)
                }).then((r) => {
                    setTimeout(() => {
                        principal();
                        setDisabledBoton(false);
                        setDisabled(true);
                        res('Datos Actualizados')
                    }, 500);

                }).catch((f) => {
                    setTimeout(() => {
                        rej(f);
                        setDisabledBoton(false)
                    }, 500);
                })
            })
            AlertaPromesa('Actualizando Datos', promesa, 'a');
        } else {
            setTimeout(() => {
                setDisabledBoton(false)
            }, 1000);
        }

    }
    return (
        <>

            <Contenedor
                titulo={'Datos de la Empresa'} rutas={['Inicio', 'Empresa']} autorizado={Permisos().empresaDdatos}
                cargar={cargar} mensajeCarga={'Obteniendo datos de la empresa'} altura={"85vh"} anchoImagen={50} spiner={false}
                error={error} mensajeError={mensaje} colorIconError={'white'} claseNameTextError={'text-xl font-bold'}
                header={<>
                    {
                        editar.usr === usuario().id ?
                            <>
                            </> :
                            <>
                                <div className="mb-3 text-justify">A modo de información, los siguientes son los datos que se visualizan en tu tarjeta digital, si observa que hay algún dato erroneo o que falta información contacte al administrador de su empresa para su respectiva actualización.</div>
                            </>
                    }
                    <Formularios datos={formulario} onChange={cambiar} />
                    <div className="text-end">
                        {
                            Autorizado([Permisos().empresaEdit]) && (
                            disabled ? <Boton tipo={'primary'} titulo={'Editar'} onClick={() => setDisabled(false)} /> :
                                <>
                                    <div className="row">
                                        <div className="col-xl-6 col-sm-6 col-lg-6"></div>
                                        <div className="col-xl-3 col-sm-3 col-lg-3 col-12 p-1 d-grid gap-2">
                                            <Boton tipo={'secondary'} titulo={'Cancelar'} onClick={() => setDisabled(true)} />
                                        </div>
                                        <div className="col-xl-3 col-sm-3 col-lg-3 col-12 p-1 d-grid gap-2">
                                            <Boton tipo={'primary'} disabled={disbaledBoton} titulo={'Guardar Cambios'} onClick={() => guardar()} />
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </>}
                


            />
            <Modales
                show={modal}
                onHide={closed}
                titulo={'Insertar Georeferencia'}
                centered={true}
                body={<Mapa latitud={coor.lat} longitud={coor.lon} enviarCoordenadas={enviarCoordenadas} />}
                footer={<>
                    <Boton tipo={'secondary'} titulo={'Cerrar'} onClick={() => closed()} />

                </>}

            />

        </>
    )
};
