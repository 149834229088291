export const DateTime = ()=> {
    const date = new Date();
    let options = { year: "numeric" };
    const year = date.toLocaleString("es-CO", options);
    options = { month: "2-digit" };
    const mes = date.toLocaleString("es-CO", options);
    options = { day: "2-digit" };
    const day = date.toLocaleString("es-CO", options);
    options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    const hora = date.toLocaleString("es-ES", options);
    return year + "-" + mes + "-" + day + " " + hora;
}

export default DateTime;