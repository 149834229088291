import { useState } from "react";
import Boton from "infortech_modules/Componentes/Boton";
import Modales from "infortech_modules/Componentes/Modales";
import { SiSahibinden } from "react-icons/si";
import Get from "infortech_modules/Crud/Get";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Permisos, { Autorizado, usuario } from "infortech_modules/Constantes/Usuarios";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import { imagenes } from "infortech_modules/Constantes/Constantes";
import { MdAddTask } from "react-icons/md";
import Post from "infortech_modules/Crud/Post";
import DateTime from "infortech_modules/Constantes/Fecha";
import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import ModalEdit from "infortech_modules/Modales/ModalEdit";
import { AiTwotoneDelete } from "react-icons/ai"


export default function VerServicios({ id, tipo, cant }) {
    tipo = tipo === undefined ? [] : tipo;
    const datos = [];
    const [cargar, setCagar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('')
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalAgregar, setModalAgregar] = useState(false);

    const open = (est) => {
        setModal(true);
        est && setCagar(true);
        Get({ url: '/get', key: 5, id: id }).then((r) => {
            setData(r)
            setTimeout(() => {
                setCagar(false);
            }, 500);
        }).catch((f) => {
            setMensaje(f);
            setTimeout(() => {
                setError(true);
                setCagar(false);
            }, 500);
        })
    }

    const closed = () => {
        setModal(false);
        setTimeout(() => {
            setError(false);
            setMensaje('');

            setCagar(true);
        }, 500);

    }

    const closedAgregar = () => {
        setModalAgregar(false);
    }

    const agregarProducto = (data0, idP) => {
        const promesa = new Promise((res, rej) => {
            console.log(cant, idP);
            Post({ url: '/post/general', key: 'UsuarioServicio', data1: id, data3: idP, data0: data0, caso: 'usuariosServiciosAdd', data2: cant }).then((r) => {
                setTimeout(() => {
                    res("Servicio agregado al usuario correctamente");
                    setModalAgregar(false);
                    open(true);

                }, 500);
            }).catch((f) => {
                setTimeout(() => {
                    rej(f);
                }, 500);
            })
        })
        AlertaPromesa('Agregando servicio al usuario...', promesa, 'a')
    }

    const row = data.map((v, i) => {
        //datosEditar[i] = { id: v.id, titulo: v.titulo, nom: v.nom, img: v.img, emp: v.emp }
        return (
            <tr key={i}>
                {
                    CreaarFila([i + 1, v.titulo, v.nom, v.img !== "" ? <img src={imagenes.servicios + v.img} className={'img-vista'} alt={`Imagen de ${v.titulo}`} /> : ""], "col", [""], "text-center")
                }
                <td className="text-center">
                    {
                        Autorizado([Permisos().usuariosServiciosEdit]) &&

                        <ModalEdit
                            IconoBotonPrincipal={AiTwotoneDelete} posicionTextoBotonPrincipal={'textText-Left'}
                            claseIconBotonPrincipal={'pointer fs-5 text-danger'} tituloBotonPrincipal={'Desasociar este servicio del usuario'}
                            contenidoSuperiorModal={<div className="text-center">¿Está seguro de Desasociar el servicio <strong>{v.titulo}</strong> del usuario?</div>}
                            inicializarDatos={() => console.log('')} modalTitulo={'Desaciar Servicios del Usuario'}
                            tituloBotonEnviar={'Desasociar'} tipoBotonEnviar={'danger'}
                            tabla={'usuarios_servicios'} editar={{ est: 0, usri: usuario().id, inh: DateTime() }} id={`id = ${v.idUp}`}
                            mensajeLoadingPromesa={'Desasociando Servicio...'} mensajePromesa={'Servicio Desasociado del usuario'}
                            url={'/put'} caso={'usuariosServiciosEdit'} principal={open}
                        />
                    }
                </td>
            </tr>
        )
    })

    const rowTipo = tipo.map((v, i) => {
        datos[i] = {
            user: id, pro: v.id, emp: usuario().emp, hab: DateTime(), usrh: usuario().id, inh: "0000-00-00 00:00:00", usri: 0, est: 1
        }
        return (
            <tr key={i}>
                {
                    CreaarFila([i + 1, v.titulo, v.nom, v.img !== "" ? <img src={imagenes.servicios + v.img} className={'img-vista'} alt={`Imagen de ${v.titulo}`} /> : ""], "col", [""], "text-center")
                }
                <td className="text-center">
                    <Boton tipo={'icono'} titulo={'Agregareste servicio'} Icono={MdAddTask} claseIcon={'fs-4 pointer text-blue-900'}
                        onClick={() => agregarProducto(datos[i], v.id, ``)}
                    />
                </td>
            </tr>
        )
    })

    return (
        <>
            <Boton tipo={'icono'} onClick={() => open()}
                titulo={'Ver Servicios Asociados'} Icono={SiSahibinden} claseIcon={'fs-4 pointer text-gray-600'} posicionTexto={"left"}
            />

            <Modales
                show={modal}
                onHide={closed}
                titulo={"Ver Servicios asociados al usuario"}
                body={
                    <>
                        <Contenedor cargar={cargar} error={error} mensajeCarga={'Obteniendo Información'} mensajeError={mensaje}
                            claseNameTextError={'fs-5 text-white p-5 text-center'} colorIconError={'white'} altura={'55vh'} autorizado={Permisos().usuariosServicios}
                            spiner={false} anchoImagen={50} classNameMensaje={'fs-5 text-white p-5 text-center'}
                            tablaEncabezado={['No.', 'Titulo', 'Descripción', 'Imagen', "Acción"]} tablaFilas={row} tablaNombre={'tpr'}
                            claseTablaEncabezado={'text-center'}
                            botonAdd={Autorizado([Permisos().usuariosServiciosEdit]) &&
                                <Boton tipo={'primary'} titulo={'Agregar Serivcio al Usuario'} onClick={() => setModalAgregar(true)} />
                            }

                        />
                    </>
                }


            />

            <Modales
                show={modalAgregar}
                onHide={closedAgregar}
                titulo={"Agregar Servicios al Usuario"}
                body={
                    <>
                        <Contenedor cargar={false}
                            autorizado={Permisos().usuarios}
                            tablaEncabezado={['No.', 'Titulo', 'Descripción', 'Imagen', "Agregar"]} tablaFilas={rowTipo} tablaNombre={'tpvr'}
                            claseTablaEncabezado={'text-center'}



                        />
                    </>
                }


            />
        </>
    )
};
