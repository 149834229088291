import axios from "axios";
import { api, header } from "infortech_modules/Constantes/Constantes";
import { Lectura } from "infortech_modules/Constantes/Lectura";


export default function Get({
    url, table, query, data0, data1, data2, data3, data4, data5, key, iniciar, id
}) {
    return new Promise((res, rej) => {
        axios.get(api + url, {
            params: {
                query: query, table: table, data0: data0, data1: data1, data2: data2, data3: data3, data4: data4, data5: data5, key: key, id: id
            },
            headers: header(),
            timeout: 5500
        })
            .then((r) => {
                if (r.data.res) {
                    res(r.data.msje)
                } else {
                    rej(Lectura(r.data.msje, iniciar))
                }
            })
            .catch((f) => {
                rej(Lectura(f, iniciar))
            })
    })
};
