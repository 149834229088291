import "infortech_modules/Recursos/Estilos/inicio.scss";
import Logo from "infortech_modules/Recursos/Imagenes/logo_if.png";
import { useState } from "react";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import Formularios from "infortech_modules/Componentes/Formularios";
import EsVacio from "infortech_modules/Constantes/EsVacio";
import { Link } from "react-router-dom";
import EsNumero from "infortech_modules/Constantes/EsNumero";
import Lectura from "infortech_modules/Constantes/Lectura";
import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import api from "infortech_modules/Constantes/Constantes";
import axios from "axios";
import { duplicate } from "infortech_modules/Constantes/Constantes";
import DateTime from "infortech_modules/Constantes/Fecha";


export default function Registrar({ iniciar }) {

    const [agregar, setAgregar] = useState({
        nom: "", ape: "", cargo: "", cel:"", tel: "", emp: 1,  img: "default.png", por: "", wa:"", fb:"", ig:"", tw:"", yt:"", user: "", mail:"",
        pass: "", url:"", onlweb: 0, onlmov: 0, ingweb: "0000-00-00 00:00:00", ingmov: "0000-00-00 00:00:00", cre: DateTime(), rol:"3",
        del: "0000-00-00 00:00:00", per: "1,2,3,4,5,6,7,8,9,11,12,13,14,16,17,27,28,29,35", bloqueo: 0, desbloqueo: "2023-01-01 00:00:00", token: "", phone: "", os: "", est: 2, plan:1, vence:null, cpas: ""
    })
    const [disabled, setDisabled] = useState(false);

    const cambiar = (e) => {       
        setAgregar({
            ...agregar,
            [e.target.name]: e.target.value
        });
        setAgregar((v) => {
            v.url = new Date ().getTime();
            return v;
        })
        
    }

    const registrar = () => {
        const te = (t, f, e) => {
            setTimeout(() => {
                f(e)
            }, t);

        }
        let tipo;
        setDisabled(true);
        const array = [agregar.nom, agregar.ape, agregar.cargo, agregar.mail, agregar.wa, agregar.user, agregar.pass, agregar.cpas, agregar.pass === agregar.cpas ? "ok" : "", agregar.pass.length > 7 ? "ok" : ""]
        const array1 = ["El nombre es obligatorio", "Digite sus apellidos", "Digite su cargo o profesión", "Digite su correo electronico", "Digite su contacto de WhatsApp", "Debe escribir su nombre de usuario", "Escribia su contraseña", "Confirme su contraseña", "Las contraseñas no coinciden", "La contraseña debe tener mínimo 8 carácteres"];
        if (EsVacio(array, array1)) {
            if(EsNumero([agregar.cel, agregar.tel === "" ? "0": agregar.tel, agregar.wa], ['El campo celular debe ser númerico', 'El campo teléfono debe ser númerico', 'Su contacto de WhatsApp debe ser númerico'])){
                const promesa = new Promise((res, rej) => {
                    axios.post(api + '/registro', {
                        duplicate:duplicate(true, [agregar.mail, agregar.user], ['mail', 'user'], ['Correo electrónico ya existe', 'Nombre de usuario ya existe']),
                        agregar:agregar
                    }).then((r) => {console.log(r);
                        setTimeout(() => {
                            setDisabled(false);
                        }, 350);
                        if(r.data.res){
                            res("Registro Correcto");
                            setTimeout(() => {
                                window.location.href = "/";
                            }, 2500);

                        }else{
                            setTimeout(() => {
                                console.log(r);
                                rej(Lectura(r.data.msje, iniciar))
                                setDisabled(false);
                            }, 350);
                            
                        }
                       
                    }).catch((f) => {
                        tipo = "e";
                        setTimeout(() => {
                            setDisabled(false);
                            rej(Lectura(f, iniciar));
                        }, 350);
                       
                        
                    })
                });
                AlertaPromesa('Registrando...', promesa, tipo);
            }else{
                te(1000, setDisabled, false)
            }
        } else {
            te(1000, setDisabled, false)
        }
    }
    

    const form = [
        [CrearInput({ type: 'text', name: 'nom', value: agregar.nom, placeholder: 'Nombres' })],
        [CrearInput({ type: 'text', name: 'ape', value: agregar.ape, placeholder: 'Apellidos' })],
        [CrearInput({ type: 'text', name: 'cargo', value: agregar.cargo, placeholder: 'Cargo o Prefesión' })],
        [CrearInput({ type: 'text', name: 'cel', value: agregar.cel, placeholder: 'Celular' })],
        [CrearInput({ type: 'text', name: 'tel', value: agregar.tel, placeholder: 'Teléfono' })],
        [CrearInput({ type: 'text', name: 'mail', value: agregar.mail, placeholder: 'Correo Electrónico' })],
        [CrearInput({ type: 'text', name: 'wa', value: agregar.wa, placeholder: 'Número de Contacto de WhatsApp' })],        
        [CrearInput({ type: 'text', name: 'user', value: agregar.user, placeholder: 'Nombre de Usuario' })],
        [CrearInput({ type: 'password', name: 'pass', value: agregar.pass, placeholder: 'Contraseña' })],
        [CrearInput({ type: 'password', name: 'cpas', value: agregar.cpas, placeholder: 'Confirmar Contraseña' })],
    ]

    const bot = [
        [CrearInput({ type: 'boton', spinner: false, placeholder: 'Iniciar Sesión', clase: 'd-grid gap-2', claeForm: 'btn btn-tema', value: { tipo: 'principal', titulo: 'Registrarse', onClick: () => registrar(), disabled: disabled } })]
    ]

    return (
        <>
            <div>
                <div className="inicio-body">
                    <div className="inicio-contenedor">
                        <div className="inicio-subcontenedor">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-sm-6 inicio-logo">
                                    <img src={Logo} className="col-9" alt="Logo INFORTECH SAS" />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-sm-6 text-center inicio-texto">
                                    <div className="p-4">
                                        <h4 className="h4 fw-bold fs-5 mb-3">Bienvenido a Su Tarjeta Virtual</h4>
                                        <div className="fs-11 mb-4">Página de Registro</div>
                                        <div className="overflow-y-scroll max-sub">
                                            <div className="scaled">
                                                {<Formularios datos={form} onChange={cambiar} />}
                                            </div>
                                            <div>{<Formularios datos={bot} />}</div>
                                            <div><Link to='/' className="no-underline font-black text-xs">Iniciar Sesión</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
