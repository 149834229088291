import axios from "axios";
import { Lectura } from "infortech_modules/Constantes/Lectura";
import { api, header } from "infortech_modules/Constantes/Constantes";
import { usuario } from "infortech_modules/Constantes/Usuarios";

export default function Delete({ table, url, where, caso, reload, value, history, key, iniciar }) {

    return new Promise((res, rej) => {
        axios.delete(api + url,
            {
                headers: header(),
                data: {
                    key: key,
                    caso: caso,
                    permisos: usuario().per,
                    table: table,
                    where: where,
                    value: value,
                    history: history,
                },
                timeout: 6500

            })
            .then((r) => {
                if (r.data.res) {
                    res(true);
                    if (reload) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    }
                } else {
                    rej(Lectura(r.data.msje, iniciar));

                }
            })
            .catch((f) => {
                rej(Lectura(f, iniciar));

            })
    })

}