import Boton from "infortech_modules/Componentes/Boton";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Formularios from "infortech_modules/Componentes/Formularios";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import Permisos, { usuario } from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import { useEffect, useState } from "react";
import EsVacio from "infortech_modules/Constantes/EsVacio";
import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import Put from "infortech_modules/Crud/Put";
import EsNumero from "infortech_modules/Constantes/EsNumero";
import { cambiarFormulario } from "infortech_modules/Constantes/Constantes";

export default function MiTarjeta({ iniciar }) {
    const [cargar, setCagar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('')
    const [editar, setEditar] = useState({
        nom: "", ape: "", cargo: "", cel: "", tel: "", img: "", por: "", wa: "", fb: "", ig: "", tw: "", yt: "", mail: "",
    })
    const [inputDisabled, setInpuitDisabled] = useState(true);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        principal();
    }, [])

    const principal = (est) => {
        est && setCagar(true);
        Get({ url: '/get', key: 6, id: usuario().id }).then((r) => {
            const u = r[0];
            setEditar({
                nom: u.nom, ape: u.ape, cargo: u.cargo, cel: u.cel, tel: u.tel, img: u.img, por: u.por, wa: u.wa, fb: u.fb, ig: u.ig, tw: u.tw, yt: u.yt, mail: u.mail,
            })
            setTimeout(() => {
                setCagar(false);
            }, 500);
        }).catch((f) => {
            setMensaje(f);
            setTimeout(() => {
                setError(true);
                setCagar(false);
            }, 500);
        })
    }

    const guardar = () => {
        setDisabled(true)
        if (EsVacio([editar.nom, editar.ape, editar.cargo, editar.cel, editar.wa], [
            "Digite sus Nombres", "Digite sus Apellidos", "Escriba Su Cargo", "Digite su numero de celular", "Digite su número de contacto por WhatsApp"
        ])) {
            if (EsNumero([editar.wa.toString(), editar.cel.toString(), editar.tel === "" ? "1":editar.tel.toString()], ["El campo contacto de WhatsApp debe ser númerico", "El valor del celular debe ser númerico", "El valor del teléfono debe ser númerico"])) {
                const promesa = new Promise((res, rej) => {
                    Put({
                        url: '/put', data0: editar, id: `id = ${usuario().id}`, caso: 'tarjetaDatosEdit', table: 'usuarios',
                    }).then((r) => {
                        setInpuitDisabled(true);
                        setTimeout(() => {
                            principal(true);
                            res('Datos Actualizados');                            
                        }, 500);

                    }).catch((f) => {
                        setTimeout(() => {
                            rej(f);
                            setDisabled(false)
                        }, 500);
                    })
                })
                AlertaPromesa('Actualizando Datos', promesa, 'a');
            } else {
                setTimeout(() => {
                    setDisabled(false)
                }, 1000);
            }


        } else {
            setTimeout(() => {
                setDisabled(false)
            }, 1000);
        }

    }

    const form = [
        [CrearInput({ type: 'text', name: 'nom', value: editar.nom, placeholder: 'Nombres', disabled: inputDisabled }),
        CrearInput({ type: 'text', name: 'ape', value: editar.ape, placeholder: 'Apellidos', disabled: inputDisabled })],
        [CrearInput({ type: 'text', name: 'cargo', value: editar.cargo, placeholder: 'Cargo o Prefesión', disabled: inputDisabled }),
        CrearInput({ type: 'text', name: 'cel', value: editar.cel, placeholder: 'Celular', disabled: inputDisabled }),
        CrearInput({ type: 'text', name: 'tel', value: editar.tel, placeholder: 'Teléfono', disabled: inputDisabled })],
        [CrearInput({ type: 'text', name: 'mail', value: editar.mail, placeholder: 'Correo Electrónico', disabled: inputDisabled }),
        CrearInput({ type: 'text', name: 'wa', value: editar.wa, placeholder: 'Número de Contacto de WhatsApp', disabled: inputDisabled })],
        [CrearInput({ type: 'text', name: 'fb', value: editar.fb, placeholder: 'Link de Facebook', disabled: inputDisabled })],
        [CrearInput({ type: 'text', name: 'ig', value: editar.ig, placeholder: 'Link de Instagram', disabled: inputDisabled })],
        [CrearInput({ type: 'text', name: 'tw', value: editar.tw, placeholder: 'Link de Twiter', disabled: inputDisabled })],
        [CrearInput({ type: 'text', name: 'yt', value: editar.yt, placeholder: 'Link de Youtube', disabled: inputDisabled })],

    ]

    const cambiar = (e) => {
        cambiarFormulario(e, editar, setEditar);
    }

    return (
        <>
            <Contenedor autorizado={Permisos().tarjetaDatos}
                cargar={cargar} mensajeCarga={'Obteniendo datos de su perfil'} spiner={false} anchoImagen={35} classNameMensaje={'fs-5 text-center p-3 text-white'}
                error={error} mensajeError={mensaje} colorIconError={'white'} claseNameTextError={"fs-5 text-center text-white p-3"} altura={'85vh'}
                rutas={['Inicio', 'Mi Tarjeta']}
                titulo={'Información de Mi Tarjeta'}
                header={<>
                    <Formularios datos={form} onChange={cambiar} />
                    {
                        inputDisabled &&
                        <div className="row mb-3">
                            <div className="col-xl-10 col-lg-10 col-sm-9"></div>
                            <div className="col-xl-2 col-lg-2 col-sm-3 col-12">
                                <div className="d-grid gap-2"> <Boton tipo={'principal'} titulo={'Editar Datos'} onClick={() => setInpuitDisabled(false)} /></div>
                            </div>
                        </div>
                    }
                    {
                        !inputDisabled &&
                        <div className="row mb-3">
                            <div className="col-xl-8 col-lg-8 col-sm-6"></div>
                            <div className="col-xl-2 col-lg-2 col-sm-3 col-12">
                                <div className="d-grid gap-2 mb-3"> <Boton tipo={'secondary'} titulo={'Cancelar'} onClick={() => setInpuitDisabled(true)} /></div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-sm-3 col-12">
                                <div className="d-grid gap-2 mb-3"> <Boton tipo={'principal'} disabled={disabled} titulo={'Actualizar'} onClick={() => guardar()} /></div>
                            </div>
                        </div>
                    }

                </>}



            />
        </>
    )
};
