import SideBarHeader from "./SideBarHeader"
import SideBarFooter from "./SideBarFooter"
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar"
import "infortech_modules/Recursos/Estilos/ProSideBar.scss";
import { useProSidebar } from "react-pro-sidebar";
import { AiFillInfoCircle } from "react-icons/ai";
import {BsImage, BsFillGeoFill} from "react-icons/bs";
import { FiMenu } from "react-icons/fi";
import { RxDashboard } from "react-icons/rx";
import { IoIosBusiness } from "react-icons/io";
import { FaRegAddressCard, FaUsers, FaProductHunt, FaImage } from "react-icons/fa";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { ImExit } from "react-icons/im"
import { Link } from "react-router-dom";
import Main from "./Main";


export default function Layout({ iniciar }) {

  const menuClasses = {
    root: 'ps-menu-root',
    menuItemRoot: 'ps-menuitem-root',
    subMenuRoot: 'ps-submenu-root',
    button: 'ps-menu-button',
    prefix: 'ps-menu-prefix',
    suffix: 'ps-menu-suffix',
    label: 'ps-menu-label',
    icon: 'ps-menu-icon',
    subMenuContent: 'ps-submenu-content',
    SubMenuExpandIcon: 'ps-submenu-expand-icon',
    disabled: 'ps-disabled',
    active: 'ps-active',
    open: 'ps-open',
  };

  const themes = {
    light: {
      sidebar: {
        backgroundColor: '#ffffff',
        color: '#607489',
      },
      menu: {
        menuContent: '#1B65D3',
        hover: {
          backgroundColor: '#A5D5FD',
          color: '#44596e',

        },
        disabled: {
          color: '#9fb6cf',
        },
      },
    },

  };



  const menuItemStyles = {
    root: {
      fontSize: '14px',
      fontWeight: 'bold',
      fontFamily: "Comfortaa"
    },
    size: 19,

    icon: {
      color: themes.light.menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes.light.menu.disabled.color,
      },

    },
    SubMenuExpandIcon: {
      color: '#b6b7b9',
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        themes.light.menu.menuContent
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes.light.menu.disabled.color,
      },
      '&:hover': {
        backgroundColor: themes.light.menu.hover.backgroundColor,
        color: themes.light.menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };


  const { toggleSidebar, broken } = useProSidebar();

  return (
    <>
      {
        broken && (
          <div className="min-width-full fixed z2">
            <div className="bg-p broken">
              <div className="col-11"></div>
              <div className="col-1"><FiMenu size={28} onClick={() => toggleSidebar()} /></div>
            </div>
          </div>
        )
      }
      <div className="ly-layout">
        <Sidebar
          breakPoint="lg"
          backgroundColor="transparent"
          className="bg-p"


        >
          <SideBarHeader />
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem className="hover" component={<Link to="/dashboard" />} icon={<RxDashboard size={menuItemStyles.size} />} >Dashboard</MenuItem>
            <SubMenu label="Empresa" icon={<IoIosBusiness size={menuItemStyles.size} />}>
              <MenuItem className="hover" component={<Link to="/empresa" />} icon={<AiFillInfoCircle size={menuItemStyles.size} />}>Datos Básicos </MenuItem>
              <MenuItem className="hover" component={<Link to="/empresa/logo" />} icon={<BsImage size={menuItemStyles.size} />}>Logo</MenuItem>
              <MenuItem className="hover" component={<Link to="/empresa/portada" />} icon={<FaImage size={menuItemStyles.size} />}>Imagen de Portada</MenuItem>
              <MenuItem className="hover" component={<Link to="/empresa/georeferencia" />} icon={<BsFillGeoFill size={menuItemStyles.size} />}>Georeferencia</MenuItem>
              <MenuItem className="hover" component={<Link to="/empresa/configuracion" />} icon={<BsFillGeoFill size={menuItemStyles.size} />}>Colores Tarjeta</MenuItem>
            </SubMenu>
            <SubMenu label="Mi Tarjeta" icon={<FaRegAddressCard size={menuItemStyles.size} />}>
              <MenuItem className="hover" component={<Link to="/tarjetas" />} icon={<AiFillInfoCircle size={menuItemStyles.size} />}>Datos Básicos</MenuItem>
              <MenuItem className="hover" component={<Link to="/tarjetas/foto" />} icon={<BsImage size={menuItemStyles.size} />}>Foto de Perfil</MenuItem>
              <MenuItem className="hover" component={<Link to="/tarjetas/portada" />} icon={<FaImage size={menuItemStyles.size} />}>Foto de Portada</MenuItem>
            </SubMenu>
            <MenuItem className="hover" component={<Link to="/usuarios" />} icon={<FaUsers size={menuItemStyles.size} />}>Usuarios</MenuItem>
            <MenuItem className="hover" component={<Link to="/productos" />} icon={<FaProductHunt size={menuItemStyles.size} />}>Productos </MenuItem>
            <MenuItem className="hover" component={<Link to="/servicios" />} icon={<MdOutlineMiscellaneousServices size={menuItemStyles.size} />}>Servicios </MenuItem>
            <MenuItem className="hover" component={<Link to="/salir" />} icon={<ImExit size={menuItemStyles.size} />}>Cerrar Sesión </MenuItem>
          </Menu>
          <SideBarFooter />
        </Sidebar>
        <main>
          <Main iniciar={iniciar} />
        </main>
      </div>
    </>
  )
};
