import Contenedor from "infortech_modules/Componentes/Contenedor";
import { cambiarFormulario } from "infortech_modules/Constantes/Constantes";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import Permisos, { Autorizado, usuario } from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import ModalEdit from "infortech_modules/Modales/ModalEdit";
import { useEffect, useState } from "react";
import VerPorductos from "./VerProdcutos";
import VerServicios from "./VerServicios";

export default function UsuariosSys({iniciar}) {
    const datos = [];
    const [cargar, setCagar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('')
    const [data, setData] = useState([]);
    const [pro, setPro] = useState([]);
    const [ser, setSer] = useState([]);
    const [editar, setEditar] = useState({
        wa:"", fb:"",ig:"",tw:"", yt:""
    })

    useEffect(() => {
        principal(); 
        // eslint-disable-next-line       
    },[])

    const principal = (est) => {
        est && setCagar(true);
        Get({url:'/get/general', key:'usuarios', data0:usuario().emp, data1:usuario().rol, data2:usuario().id}, iniciar={iniciar}).then((r) =>{           
            setTimeout(() => {
                setData(r[0]);
                setPro(r[1]);
                setSer(r[2]);
                setCagar(false);

            }, 500);
        }).catch((f) => {
            setMensaje(f);
            setTimeout(() => {
                setCagar(false);
                setError(true);                
            }, 500);
        })              
    }

    const inicializarDatos = (data) => {
        setEditar(data);
    }

    const formEditar = [
        [CrearInput({type:'text', name:'wa', value:editar.wa, placeholder:'Contacto de WhastApp'})],
        [CrearInput({type:'text', name:'fb', value:editar.fb, placeholder:'Link de Facebook'})],
        [CrearInput({type:'text', name:'ig', value:editar.ig, placeholder:'Link de Instagram'})],
        [CrearInput({type:'text', name:'tw', value:editar.tw, placeholder:'Link de Twiter'})],
        [CrearInput({type:'text', name:'yt', value:editar.yt, placeholder:'Link de Youtube'})],
    ]

    const row = data.map((v,i) => {
        datos[i] = {wa:v.wa, fb:v.fb,ig:v.ig,tw:v.tw, yt:v.yt}
        return (
            <tr key={i}>
                {
                    CreaarFila([i+1, v.nom + ' '+ v.ape, v.cargo, v.tel, v.mail, v.wa, v.fb, v.ig, v.tw, v.yt, v.plan], "scope", [""], "text-center")                    
                }
                <td className="text-center">
                    {
                        Autorizado([Permisos().usuariosEdit]) && 
                        <ModalEdit
                        inicializarDatos={() => inicializarDatos(datos[i])}
                        modalTitulo={'Editar Redes Sociales del Usuario'}
                        formulario={formEditar} onChange={(e) => cambiarFormulario(e, editar, setEditar)}
                        numeroComprobar={[editar.wa === "" ? "1":editar.wa.toString()]} mensajeNumeroComprobar={['El contacto de WhatsApp debe ser un valor númerico']}                   
                        mensajeLoadingPromesa={'Actualizando Datos'} mensajePromesa={'Datos Actualizados'}
                        url={'/put'} caso={'Usuarios'}  id={`id = ${v.id}`} iniciar={iniciar} editar={editar} tabla={'usuarios'}
                        principal={principal}                   
                        />     

                    }
                  
                    &nbsp;   
                    {
                        Autorizado([Permisos().usuariosProductos]) && (
                        v.planId !== 1 &&                        
                        <VerPorductos
                        id={v.id} tipo={pro} cant={v.pro}                  
                        />
                        )
                    }
                    &nbsp;
                    {
                        Autorizado([Permisos().usuariosServicios]) && (
                        v.planId !== 1 && 
                        <VerServicios 
                        id={v.id} tipo={ser} cant={v.ser}
                        />
                        )
                    }
                                                                                            
                </td>
            </tr>
        )
    })

    return (
        <>
        <Contenedor
        rutas={['Inicio', 'Usuarios']}
        cargar={cargar} error={error} mensajeError={mensaje} mensajeCarga={'Obteniendo Información de Usuarios'} colorIconError={'white'}
        altura={'85vh'} classNameMensaje={'fs-5'} spiner={false} anchoImagen={50} claseNameTextError={'p-2 text-white fs-5'} autorizado={Permisos().usuarios}
        titulo={'Usuarios'}
        tablaEncabezado={['No.', "Usuario", "Cargo", "Teléfono", "Correo", "Contacto WhastApp", "Facebook", "Instragram", "Twiter", "Youtube", "Plan", "Acciones"]}
        tablaNombre={'usr'} claseTablaEncabezado={'text-center'} tablaFilas={row}
        />
        </>
    )
};
