import { useState, useEffect } from "react";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Get from "infortech_modules/Crud/Get";
import Permisos, { usuario } from "infortech_modules/Constantes/Usuarios";
import {  imagenes } from "infortech_modules/Constantes/Constantes";
import Mapa from "infortech_modules/Componentes/Mapa";
import Modales from "infortech_modules/Componentes/Modales";
import Boton from "infortech_modules/Componentes/Boton";
import { urlMaps } from "infortech_modules/Constantes/Constantes";
import Put from "infortech_modules/Crud/Put";
import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import { duplicate } from "infortech_modules/Constantes/Constantes";


export default function Georeferencia({ iniciar }) {
    useEffect(() => {
        principal();
        // eslint-disable-next-line
    }, [])
    const [cargar, setCagar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('')
    const [editar, setEditar] = useState({
        nit: "", nom: "", dir: "", tel: "", ciu: "", dep: "", pais: "", lat: "", lon: "", link: "", logo: "", fot: "", des: ""
    })
    const [modal, setModal] = useState(false);
    const [coor, setCoor] = useState({ lon: undefined, lat: undefined });


    const open = () => {
        setModal(true);
    }

    const closed = () => {
        setModal(false);
    }

    const principal = (est) => {
        est && setCagar(true);
        Get({ url: '/get', key: 3, id: usuario().emp }, iniciar = { iniciar }).then((r) => {
            setTimeout(() => {
                setEditar(r[0]);
                setCoor({lat:r[0].lat !== ""?parseFloat(r[0].lat):undefined, lon:r[0].lon !== ""? parseFloat(r[0].lon):undefined})
                setCagar(false)
            }, 500);
        }).catch((f) => {
            setError(true);
            setMensaje(f)
            setTimeout(() => {
                setCagar(false)
            }, 500);
        })
    }

 

    const enviarCoordenadas = (coordenadas) => {
        const enviar = {lat:coordenadas.lat, lon:coordenadas.lng, link:urlMaps(coordenadas, 16)};
        const promesa = new Promise ((res, rej) => {
            Put({
                url: '/put', data0: enviar, id: `id = ${editar.id}`, caso: 'EmpresasGeo', table: 'empresas',
                duplicate: duplicate(false)
            }).then((r) => {
                principal(true);
                setTimeout(() => {
                    res('Coordenadas Actualizadas');
                    setModal(false);

                }, 500);
            

            }).catch((f) => {
                rej(f)                
            })
                    
        });

        AlertaPromesa('Actualizando Coordenadas', promesa, "a")
        

        
   
    }


    const SinCoordenadas = <div className="text-justify mb-3">Su empresa no tiene registro de georeferenciación. Si es el administrador del sistema inserte la georeferncia de su empresa en el botón "Insertar Coordenadas" de lo contratio informe a su administrador de su empresa.</div>

    return (
        <>

            <Contenedor
                titulo={'Georeferencia de su Empresa'} rutas={['Inicio', 'Empresa', 'Georeferencia']} autorizado={Permisos().empresaGeo}
                cargar={cargar} mensajeCarga={'Obteniendo datos de la empresa'} altura={"85vh"} anchoImagen={50} spiner={false}
                error={error} mensajeError={mensaje} colorIconError={'white'} claseNameTextError={'text-xl font-bold'}
                header={<>
                    {
                        editar.usr === usuario().id ?
                            <>

                            </> :
                            <div className="text-justify mb-3">
                                A continuación se observa la georeferencia relacionada en el apartado Empresa de su tarjeta virtual, si cree que hay un error informe al administrador del sistema para su actualización
                            </div>
                    }
                    {
                        editar.lat === undefined ? SinCoordenadas : editar.lat === "" ? SinCoordenadas : editar.lat === "" ? SinCoordenadas : editar.lon === undefined ? SinCoordenadas :
                            editar.lon === "" ? SinCoordenadas :
                                <Mapa latitud={parseFloat(editar.lat)} longitud={parseFloat(editar.lon)} boton={false} iconMapa={imagenes.logos + editar.logo} />

                    }

                    {
                        editar.usr === usuario().id &&
                        <div className="row p-3">
                            <div className="col-xl-9 col-sm-9 col-lg-9"></div>
                            <div className="col-xl-3 col-sm-3 col-lg-3 col-12 p-1 d-grid gap-2">
                                <Boton titulo={
                                    editar.lat === undefined ? "Insertar Coordendas" : editar.lat === "" ? "Insertar Coordenadas" : editar.lat === "" ? "Insertar Coordenadas" : editar.lon === undefined ? "Insertar Coordenadas" :
                                        editar.lon === "" ? "Insertar Coordenadas" : "Cambiar Coordenadas"
                                } tipo={'secondary'} onClick={() => open()} />
                            </div>

                        </div>

                    }




                </>}


            />
            <Modales
                show={modal}
                onHide={closed}
                titulo={'Colocar Esta Ubicación'}
                centered={true}
                body={<Mapa latitud={coor.lat} longitud={coor.lon} enviarCoordenadas={enviarCoordenadas} />}
                footer={<>
                    <Boton tipo={'secondary'} titulo={'Cerrar'} onClick={() => closed()} />

                </>}

            />

        </>
    )
};
