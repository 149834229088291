export default function SideBarFooter() {
    return (
        <>
            <div className="sbf-pos relative">
                <div className="sbf-footer">
                    <div className="sbf-style">
                        <div>
                            INFORTECH SAS
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
