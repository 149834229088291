import { useState } from "react";
import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import Modales from "infortech_modules/Componentes/Modales";
import Boton from "infortech_modules/Componentes/Boton";
import { BiCommentX } from "react-icons/bi"
import Delete from "infortech_modules/Crud/Delete";



export default function ModalDelete({
    tipoBotonPrincipal, IconoBotonPrincipal, SpinnerBotonPrincipal, claseIconBotonPrincipal, tituloBotonPrincipal, widthBotonPrincipal,
    tipoBotonSecundario, IconoBotonSecundario, SpinnerBotonSecundario, claseIconBotonSecundario, tituloBotonSecundario, widthBotonSecundario,
    tipoBotonEnviar, IconoBotonEnviar, SpinnerBotonEnviar, claseIconBotonEnviar, tituloBotonEnviar, widthBotonEnviar, 
    modalSize, modalTitulo, modalCentrado,  mensajeEliminar, mensajeAdicionalEliminar, mensajeLoadingPromesa, mensajePromesa, consultarHistorial,
    recargar, principal, tipoMensajePromesa, tiempoMensajePromesa, iniciar,caso, key1, small, condicionEliminar, valorEliminar,
    cargarSpiner, tabla, url,
}) {
    tipoBotonPrincipal = tipoBotonPrincipal === undefined ? 'icono' : tipoBotonPrincipal;
    IconoBotonPrincipal = IconoBotonPrincipal === undefined ? BiCommentX : IconoBotonPrincipal;
    tituloBotonPrincipal = tituloBotonPrincipal === undefined ? 'Eliminar' : tituloBotonPrincipal;
    claseIconBotonPrincipal = claseIconBotonPrincipal === undefined ? "fs-4 pointer text-red-500" : claseIconBotonPrincipal;

    tipoBotonSecundario = tipoBotonSecundario === undefined ? 'secondary' : tipoBotonSecundario;
    tituloBotonSecundario = tituloBotonSecundario === undefined ? 'Cerrar' : tituloBotonSecundario;

    tipoBotonEnviar = tipoBotonEnviar === undefined ? 'danger' : tipoBotonEnviar;
    tituloBotonEnviar = tituloBotonEnviar === undefined ? 'Eliminar' : tituloBotonEnviar;

    small = small === undefined ? true : small;
    recargar = recargar === undefined ? false : recargar;

    cargarSpiner = cargarSpiner === undefined ? false : cargarSpiner;
    mensajeAdicionalEliminar = mensajeAdicionalEliminar === undefined ? "Una vez eliminado, no podra reversar los cambios.":mensajeAdicionalEliminar;


    const [modal, setModal] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const open = () => {
        setModal(true);
    }

    const cerrar = () => setModal(false);

    const procesar = () => {
        const enviar = () => {
            setDisabled(true);

            const promesa = new Promise((res, rej) => {
                Delete(({ table: tabla, url: url, where: condicionEliminar, caso: caso, reload: recargar, value: valorEliminar, history: consultarHistorial, key: key1, iniciar: iniciar }))
                    .then((r) => {
                        if (r) {
                            if (mensajePromesa !== '') {
                                setTimeout(() => {
                                    res(mensajePromesa);
                                }, 500);
                            }

                            if (!recargar) {
                                setTimeout(() => {
                                    setModal(false);
                                    principal(true);
                                }, 1000);
                            }
                        } else {
                            setTimeout(() => {
                                rej(r)
                            }, 500);
                        }

                    }).catch((f) => {
                        setTimeout(() => {
                            rej(f)
                        }, 500);
                    })
            })

            AlertaPromesa(mensajeLoadingPromesa, promesa, tipoMensajePromesa, tiempoMensajePromesa);
        }
        enviar();


        setTimeout(() => {
            setDisabled(false);
        }, 2500);
    }


    return (
        <>
            {
                <Boton tipo={tipoBotonPrincipal} Icono={IconoBotonPrincipal} SpinnerBoton={SpinnerBotonPrincipal}
                    claseIcon={claseIconBotonPrincipal} onClick={() => open()} titulo={tituloBotonPrincipal}
                    width={widthBotonPrincipal}
                />
            }

            <Modales
                show={modal}
                onHide={cerrar}
                size={modalSize}
                titulo={modalTitulo}
                centered={modalCentrado}
                body={
                    <>
                    <div className="text-center">
                            <div className="mb-3">
                                {mensajeEliminar}
                            </div>
                            <div>
                                <strong><i>{mensajeAdicionalEliminar}</i></strong>
                            </div>

                        </div>                     
                    </>
                }
                footer={
                    <>

                        <Boton tipo={tipoBotonSecundario} Icono={IconoBotonSecundario} SpinnerBoton={SpinnerBotonSecundario}
                            claseIcon={claseIconBotonSecundario} onClick={() => cerrar()} titulo={tituloBotonSecundario}
                            width={widthBotonSecundario}
                        />
                        <Boton tipo={tipoBotonEnviar} Icono={IconoBotonEnviar} SpinnerBoton={SpinnerBotonEnviar}
                            claseIcon={claseIconBotonEnviar} disabled={disabled} onClick={() => procesar()} titulo={tituloBotonEnviar}
                            width={widthBotonEnviar}
                        />


                    </>
                }
            />

        </>
    )
};
