import { useState, useEffect } from "react";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Get from "infortech_modules/Crud/Get";
import Permisos, { Autorizado, usuario } from "infortech_modules/Constantes/Usuarios";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import Formularios from "infortech_modules/Componentes/Formularios";
import { cambiarFormulario, imagenes } from "infortech_modules/Constantes/Constantes";
import Modales from "infortech_modules/Componentes/Modales";
import Boton from "infortech_modules/Componentes/Boton";
import { BiEditAlt } from "react-icons/bi";
import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import Put from "infortech_modules/Crud/Put";
import { duplicate } from "infortech_modules/Constantes/Constantes";
import EsVacio from "infortech_modules/Constantes/EsVacio";
import Upload from "infortech_modules/Crud/Upload";

export default function Logo({ iniciar }) {
    useEffect(() => {
        principal();
        // eslint-disable-next-line
    }, [])
    const [cargar, setCagar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('')
    const [editar, setEditar] = useState({
        nit: "", nom: "", dir: "", tel: "", ciu: "", dep: "", pais: "", lat: "", lon: "", link: "", logo: "", fot: "", des: ""
    })
    const [img, setImg] = useState(undefined);
    const [modal, setModal] = useState(false);
    const [disbaledBoton, setDisabledBoton] = useState(false);


    const open = () => {
        setModal(true);
    }

    const closed = () => {
        setModal(false);
    }

    const principal = (est) => {
        est && setCagar(true)
        Get({ url: '/get', key: 3, id: usuario().emp }, iniciar = { iniciar }).then((r) => {
            setTimeout(() => {
                setEditar(r[0]);
                setCagar(false)
            }, 500);
        }).catch((f) => {
            setError(true);
            setMensaje(f)
            setTimeout(() => {
                setCagar(false)
            }, 500);
        })
    }


    const formulario = [
        [CrearInput({ type: 'file', name: "logo", placeholder: 'Nuevo Logo' })]
    ]


    const cambiar = (e) => {
        cambiarFormulario(e, editar, setEditar, setImg)
    }

    const guardar = () => {
        setDisabledBoton(true)
        if (EsVacio([img !== undefined ? "ok" : ""], [
            "No ha cargado imagen para guardar"
        ])) {
            const promesa = new Promise((res, rej) => {

                Upload('photo', img, '/Upload/logos.php').then((u) => {
                    const enviar = { logo: u };

                    Put({
                        url: '/put', data0: enviar, id: `id = ${editar.id}`, caso: 'empresasLogoEdit', table: 'empresas',
                        duplicate: duplicate(false)
                    }).then((r) => {
                        setTimeout(() => {
                            setImg(undefined);
                            principal(true);
                            setDisabledBoton(false);                            
                            closed();
                            res('El Logo de su empresa ha sido actualizado');
                        }, 500);

                    }).catch((f) => {
                        setTimeout(() => {
                            rej(f);
                            setDisabledBoton(false)
                        }, 500);
                    })

                }).catch((f) => {
                    rej(f);
                    setDisabledBoton(false)
                }, 500);
            })

            AlertaPromesa('Actualizando Datos', promesa, 'a');
        } else {
            setTimeout(() => {
                setDisabledBoton(false)
            }, 1000);
        }

    }


    return (
        <>

            <Contenedor
                titulo={'Logo Empresa'} rutas={['Inicio', 'Empresa', 'Logo']} autorizado={Permisos().empresaLogo}
                cargar={cargar} mensajeCarga={'Obteniendo datos de la empresa'} altura={"85vh"} anchoImagen={50} spiner={false}
                error={error} mensajeError={mensaje} colorIconError={'white'} claseNameTextError={'text-xl font-bold'}
                header={<>
                    {
                        editar.usr === usuario().id ?
                            <>

                            </> :
                            <div className="mb-4">
                                Si dese cambiar el logo, comniquese con el administrador de su empresa para actualizar
                            </div>
                    }


                    {
                        editar.logo !== "" && editar.logo !== undefined &&
                        <>
                            <div className="h3 text-center p-3"> &nbsp;
                                {
                                    Autorizado([Permisos().empresaLogoEdit]) &&
                                    <div className='infoText'>
                                        <BiEditAlt
                                            className={"fs-2 pointer text-blue-700"}
                                            onClick={() => open()}
                                        />
                                        <span className='textText-Top'>{'Cambiar Logo'}</span>
                                    </div>



                                }
                            </div>
                            <div className="l-logo-c">
                                <div className="l-logo" style={{ backgroundImage: `url(${imagenes.logos + editar.logo})` }}></div>
                            </div>



                        </>
                    }
                </>}


            />
            <Modales
                show={modal}
                onHide={closed}
                titulo={'Cambiar Logo'}
                centered={true}
                body={<>{
                    <Formularios datos={formulario} onChange={cambiar} />
                }
                </>}

                footer={<>
                    <Boton tipo={'secondary'} titulo={'Cerrar'} onClick={() => closed()} />
                    <Boton tipo={'primary'} disabled={disbaledBoton} titulo={'Cambiar'} onClick={() => guardar()} />

                </>}

            />

        </>
    )
};
