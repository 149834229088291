import { useState, useEffect } from "react";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Permisos, { usuario } from "infortech_modules/Constantes/Usuarios";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import Formularios from "infortech_modules/Componentes/Formularios";
import { cambiarFormulario, duplicate, urlMaps } from "infortech_modules/Constantes/Constantes";
import Boton from "infortech_modules/Componentes/Boton";
import Modales from "infortech_modules/Componentes/Modales";
import Mapa from "infortech_modules/Componentes/Mapa";
import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import EsVacio from "infortech_modules/Constantes/EsVacio";
import Post from "infortech_modules/Crud/Post";
import Upload from "infortech_modules/Crud/Upload";



export default function NweEmpresa({ iniciar }) {
    useEffect(() => {
        principal();
        // eslint-disable-next-line
    }, [])
    const [cargar, setCagar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('')
    const [agregar, setAgregar] = useState({
        nit: "", nom: "", dir: "", tel: "", ciu: "", dep: "", pais: "", lat: "", lon: "", link: "", logo: "", fot: "", des: "", usr: usuario().id, web:"",
    })
    const [img, setImg] = useState(undefined);
    const [disableBoton, setDisabledBoton] = useState(false);
    const [modal, setModal] = useState(false);
    const [coor, setCoor] = useState({ lon: undefined, lat: undefined });


    const open = () => {
        setModal(true);
    }

    const closed = () => {
        setModal(false);
    }

    const principal = () => {
        if (usuario().emp === 1) {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    setCoor({ lat: position.coords.latitude, lon: position.coords.longitude })
                },
                );
            }
            setTimeout(() => {
                setCagar(false);
            }, 500);

        } else {
            setMensaje('Lo sentimos, usted ya creo su empresa, si ya la creo y no puede ver los datos de su empresa o si cree qie se trata de un error, informe a soporte técnico al Cel 3214437346 o escribanos a soporte@infortech.net.co');
            setTimeout(() => {
                setCagar(false);
                setError(true);
            }, 500);

        }
    }

    const procesar = () => {
        setDisabledBoton(true);

        const enviarFile = () => {
            if (img !== undefined) {
                const promesa = new Promise((res, rej) => {
                    Upload('photo', img, '/Upload/logos.php').then((u) => {
                        agregar.logo = u;
                        Post(({
                            table: 'empresas', url: '/post/general', duplicate: duplicate(true, [agregar.nit], ['nit'], ['Este NIT ya esta registrado']), caso: 'EmpresasAdd', data0: agregar, data1: usuario().id,
                            mensaje: "Empresa registrada correctamente", reload: false, key: 'EmpresasAdd', iniciar: iniciar,
                        }))
                            .then((r) => {
                                localStorage.clear();
                                localStorage.setItem('ingreso', r)

                                setTimeout(() => {
                                    res("Datos guardados");
                                    window.location.reload();
                                }, 500);

                            }).catch((f) => {
                                setTimeout(() => {
                                    rej(f)
                                    setDisabledBoton(false);
                                }, 500);
                            })
                    }).catch((f) => {
                        setTimeout(() => {
                            console.log(f);
                            rej(f)
                            setDisabledBoton(false);
                        }, 500);
                    })

                });
                AlertaPromesa("Registrando datos de su empresa", promesa, "a");
            } else {
                enviar();
            }
        }

        const enviar = () => {
            const promesa = new Promise((res, rej) => {
                Post(({
                    table: 'empresas', url: '/post/general', duplicate: duplicate(true, [agregar.nit], ['nit'], ['Este NIT ya esta registrado']), caso: 'EmpresasAdd', data0: agregar, data1: usuario().id,
                    mensaje: "Empresa registrada correctamente", reload: false, key: 'EmpresasAdd', iniciar: iniciar
                }))
                    .then((r) => {
                        localStorage.clear();
                        localStorage.setItem('ingreso', r)

                        setTimeout(() => {
                            res("Datos guardados");
                            window.location.reload();
                        }, 500);

                    }).catch((f) => {
                        setTimeout(() => {
                            rej(f)
                            setDisabledBoton(false);
                        }, 500);
                    })
            })
            AlertaPromesa("Registrando datos de su empresa", promesa, "a");
        }

        if (EsVacio([agregar.nit, agregar.nom, agregar.dir, agregar.tel, agregar.ciu, agregar.dep, agregar.pais],
            ["Escriba el Número de NIT de la empresa", "Escriba el nombre de la empresa", "Digite la dirección de la empresa", "Digite el número de teléfono", "Digite la ciudad de la empresa",
                "Digite el departamento", "Digite el país"
            ]
        )) {
            enviarFile();
        } else {
            setTimeout(() => {
                setDisabledBoton(false);
            }, 1000);
        }
    }

    const formulario = [
        [CrearInput({ type: 'text', name: "nit", value: agregar.nit, placeholder: "NIT No.", clase: 'col-xl-3 col-sm-3 col-lg-3 col-12' }), CrearInput({ type: 'text', name: 'nom', value: agregar.nom, placeholder: "Nombre Empresa",  clase: 'col-xl-9 col-sm-9 col-lg-9 col-12' })],
        [CrearInput({ type: 'text', name: 'dir', value: agregar.dir, placeholder: 'Dirección', clase: 'col-xl-7 col-sm-7 col-lg-7 col-12' }), CrearInput({ type: 'text', name: 'tel', value: agregar.tel, placeholder: "Teléfono",  clase: 'col-xl-5 col-sm-5 col-lg-5 col-12' })],
        [CrearInput({ type: 'text', name: 'ciu', value: agregar.ciu, placeholder: 'Ciudad', clase: 'col-xl-7 col-sm-7 col-lg-7 col-12' }), CrearInput({ type: 'text', name: 'dep', value: agregar.dep, placeholder: "Departamento",  clase: 'col-xl-5 col-sm-5 col-lg-5 col-12' }),
        CrearInput({ type: 'text', name: 'pais', value: agregar.pais, placeholder: 'País', clase: 'col-xl-7 col-sm-7 col-lg-7 col-12' }), CrearInput({ type: 'file', name: 'logo', placeholder: "Logo o Imagen",  clase: 'col-xl-5 col-sm-5 col-lg-5 col-12' }),
        ],
        [CrearInput({ type: 'text-area', name: 'des', value: agregar.des, placeholder: "Información General de la Empresa" })],
        [CrearInput({ type: 'text', name: 'web', value: agregar.web, placeholder: "Página Web" })],
    ]

    const cambiar = (e) => {
        cambiarFormulario(e, agregar, setAgregar, setImg)
    }
    const enviarCoordenadas = (coordenadas) => {
        setCoor({ lat: coordenadas.lat, lon: coordenadas.lng });
        setAgregar((v) => {
            v.lat = coordenadas.lat;
            v.lon = coordenadas.lng;
            v.link = urlMaps(coordenadas, 16)
            return v;
        })
        setModal(false);
    }



    return (
        <>

            <Contenedor
                titulo={'Datos de la Empresa'} rutas={['Inicio', 'Empresa']} autorizado={Permisos().empresaAdd}
                cargar={cargar} mensajeCarga={'Obteniendo datos de la empresa'} altura={"85vh"} anchoImagen={50} spiner={false}
                error={error} mensajeError={mensaje} colorIconError={'white'} claseNameTextError={'text-xl font-bold p-3 text-center'}
                header={<>
                    <Formularios datos={formulario} onChange={cambiar} />
                    <div className="row">
                        <div className="col-xl-6 col-sm-6 col-lg-6"></div>
                        <div className="col-xl-3 col-sm-3 col-lg-3 col-12 p-1 d-grid gap-2">
                            <Boton titulo={'Insertar Georeferencia'} tipo={'secondary'} onClick={() => open()} />
                        </div>
                        <div className="col-xl-3 col-sm-3 col-lg-3 col-12 p-1 d-grid gap-2">
                            <Boton titulo={'Guardar Datos'} tipo={'primary'} disabled={disableBoton} onClick={() => procesar()} />
                        </div>
                    </div>

                </>}
            />
            <Modales
                show={modal}
                onHide={closed}
                titulo={'Insertar Georeferencia'}
                centered={true}
                body={<Mapa latitud={coor.lat} longitud={coor.lon} enviarCoordenadas={enviarCoordenadas} />}
                footer={<>
                    <Boton tipo={'secondary'} titulo={'Cerrar'} onClick={() => closed()} />

                </>}

            />
        </>
    )
};
