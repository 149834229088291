import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useState } from 'react';
import Boton from './Boton';
import { BsCheckCircleFill } from 'react-icons/bs';

const containerStyle = {
    width: '100wh',
    height: '60vh'
};


export default function Mapa({ latitud, longitud, enviarCoordenadas, boton, iconMapa }) {
    latitud = latitud === undefined ? 5.1772538 : latitud;
    longitud = longitud === undefined ? -72.5559743 : longitud;
    boton = boton === undefined ? true : boton;

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDpAiPutgGQSOJPJwJ43V4OnE5X0hwdDO0"
    })

    const [coor, setCoor] = useState({ lat: latitud, lng: longitud })




    function enviarDatos(u) {
        setCoor({ lat: u.latLng.lat(), lng: u.latLng.lng() })
    }


    return isLoaded ? (
        <>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={coor}
                zoom={15}
                onClick={((e) => enviarDatos(e))}
            >
                <Marker
                    position={coor}        
                />
            </GoogleMap>
            {
                boton &&
                <div className='text-end mt-3'>
                    <Boton tipo={'primary'} Icono={BsCheckCircleFill} titulo={'Insertar esta ubicación'} sizeIcon={25} onClick={() => enviarCoordenadas(coor)} />
                </div>
            }
        </>

    ) : <></>
}





