import Productos from "2Access/5Productos/Productos";
import Servicios from "2Access/6Servicios/Servicios";
import Dashboard from "2Access/Dashboard";
import DatosBasicos from "2Access/2Empresa/DatosBasicos";
import Logo from "2Access/2Empresa/Logo";
import { Route, Routes } from "react-router-dom";
import { usuario } from "infortech_modules/Constantes/Usuarios";
import NewEmpresas from "2Access/2Empresa/NewEmpresa";
import Portada from "2Access/2Empresa/Portada";
import Georeferencia from "2Access/2Empresa/Georeferencia";
import UsuariosSys from "1Loguin/4Usuarios/UsuariosSys";
import MiTarjeta from "2Access/3Tarjetas/MiTarjeta";
import FotoPerfil from "2Access/3Tarjetas/FotoPerfil";
import FotoPortada from "2Access/3Tarjetas/FotoPortada";
import Cerrar from "2Access/7Cerrar/Cerrar";
import ConfigTarjeta from "2Access/2Empresa/ConfigTarjeta";

export default function Main({iniciar}) {
    return (
        <>
            <div>
                <div className='container-fluid main-contenedor'>
                    <div className='shadow-lg p-3 mb-3 bg-body rounded'>
                        <div style={{ minHeight: '90vh' }}>
                        <Routes>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/empresa" element={usuario().rol === 100 ?<NewEmpresas iniciar={iniciar} />:<DatosBasicos iniciar={iniciar} />} />
                            <Route path="/empresa/logo" element={<Logo iniciar={iniciar}/>} />
                            <Route path="/empresa/portada" element={<Portada iniciar={iniciar}/>} />
                            <Route path="/empresa/georeferencia" element={<Georeferencia iniciar={iniciar}/>} />
                            <Route path="/empresa/configuracion" element={<ConfigTarjeta iniciar={iniciar}/>} />
                            <Route path="/tarjetas" element={<MiTarjeta iniciar={iniciar} />} />
                            <Route path="/tarjetas/foto" element={<FotoPerfil iniciar={iniciar} />} />
                            <Route path="/tarjetas/portada" element={<FotoPortada iniciar={iniciar} />} />
                            <Route path="/usuarios" element={<UsuariosSys iniciar={iniciar} />} />
                            <Route path="/productos" element={<Productos iniciar={iniciar} />} />
                            <Route path="/servicios" element={<Servicios iniciar={iniciar} />} />
                            <Route path="/salir" element={<Cerrar iniciar={iniciar} />} />
                            <Route path="/" element={<Dashboard iniciar={iniciar} />} />
                        </Routes>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
};
