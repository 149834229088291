import Contenedor from "infortech_modules/Componentes/Contenedor";
import Permisos, { usuario } from "infortech_modules/Constantes/Usuarios";
import Put from "infortech_modules/Crud/Put";
import { useEffect, useState } from "react";

export default function Cerrar({iniciar}) {
    const [cargar, setCagar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('');

    useEffect(() => {
        principal();
        //eslint-disable-next-line
    },[])

    const principal = () => {
        Put({url:'/salir',  id:`id = ${usuario().id}`, data0:{onlweb:0}, table:'usuarios'}).then((r) => {
            window.location.href="/";
            localStorage.clear();
           setTimeout(() => {            
            iniciar();
           }, 500);
        }).catch((f) => {
            setMensaje(f);
            setTimeout(() => {
                setCagar(false);
                setError(true);                
            }, 500);
        })
    }


    return (
        <>
        <Contenedor autorizado={Permisos().salir}
        cargar={cargar} spiner={false} anchoImagen={50} mensajeCarga={'Cerrando su Sesión'} classNameMensaje={'fs-5'} altura={'85vh'}
        error={error} colorIconError={'white'} claseNameTextError={'fs-5'} mensajeError={mensaje}
        />
        </>
    )
};
