import { useEffect, useState } from "react";
import SelectColor from "./SelectColor";
import Permisos, { usuario } from "infortech_modules/Constantes/Usuarios";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Get from "infortech_modules/Crud/Get";
import Post from "infortech_modules/Crud/Post";
import { duplicate } from "infortech_modules/Constantes/Constantes";
import Alertas, { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import Boton from "infortech_modules/Componentes/Boton";
import { cambiarFormulario } from "infortech_modules/Constantes/Constantes";
import Upload from "infortech_modules/Crud/Upload";
import Put from "infortech_modules/Crud/Put";
export default function ConfigTarjeta({ iniciar }) {
    const [cargar, setCagar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [agregar, setAgregar] = useState({
        idEmp: usuario().emp,
        logo: "",
        colorEncabezadoStart: { "r": 24, "g": 89, "b": 180, "a": 1 },
        colorEncabezadoEnd: { "r": 4, "g": 103, "b": 241, "a": 1 },
        textoEncabezado: { "r": 255, "g": 255, "b": 255, "a": 1 },
        colorTarjetaStart: { "r": 240, "g": 248, "b": 255, "a": 1 },
        colorTarjetaEnd: { "r": 240, "g": 248, "b": 255, "a": 1 },
        textoTarjeta: { "r": 0, "g": 0, "b": 0, "a": 1 }
    })
    const [archivo, setArchivo] = useState(undefined);
    const [editar, setEditar] = useState({logo:""});


    useEffect(() => {
        principal();
    }, [])

    const principal = (est) => {
        est && setCagar(true);
        Get({ url: '/get', key: '7', id: usuario().emp }).then((r) => {
            if (r.length > 0) {
                const d = r[0];
                setAgregar({
                    idEmp: d.idEmp,
                    logo: d.logo,
                    colorEncabezadoStart: JSON.parse(d.colorEncabezadoStart),
                    colorEncabezadoEnd: JSON.parse(d.colorEncabezadoEnd),
                    textoEncabezado: JSON.parse(d.textoEncabezado),
                    colorTarjetaStart: JSON.parse(d.colorTarjetaStart),
                    colorTarjetaEnd: JSON.parse(d.colorTarjetaEnd),
                    textoTarjeta: JSON.parse(d.textoTarjeta),
                })
            }
            setTimeout(() => {
                setCagar(false);
            }, 500);

        }).catch((f) => {
            setError(true);
            setTimeout(() => {
                setCagar(false);
                setMensaje(f);
            }, 500);
        })
    }
    const definirColor = (color) => {
        const r = color.r;
        const g = color.g;
        const b = color.b;
        const a = color.a;
        return ('rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')');
    }

    const establecerColor = (color, dato) => {
        setAgregar({
            ...agregar,
            [dato]: color
        })
    }

    const tam = '25px';

    const enviar = (data) => {
        const promesa = new Promise((res, rej) => {
            Post({ url: '/post/general', caso: 'empresaDatos', duplicate: duplicate(false), data0: data, table: usuario().emp, key: 'EmpresasConfig', }).then((r) => {
                res("Configuración Guardada");
                principal(true);
            }).catch((f) => {
                rej(f)
            })
        })
        AlertaPromesa('Guardando Configuración', promesa, "a")
    }

    const enviarImagen = () => {
        if(archivo !== undefined) {
            const promesa = new Promise((res,rej) => {
                Upload('photo', archivo, '/Upload/imgFooter.php').then((r) => {
                    editar.logo = r;
                    Put({url:'/put', caso:'empresaEdit', duplicate: duplicate(false), data0:editar, table:'congfig_empresa', id:`idEmp = ${usuario().emp}`,
                }).then((rs) => {
                    res('Imagen Actualizada')
                }).catch((f) => rej(f))                 
                }).catch((f) => {
                    rej(""+f)
                })
            })
            AlertaPromesa('Cargando Imagen, por favor espere...', promesa, 'a');
        }else{
            Alertas('a', 'Primero Seleccione la Imagen')
        }
    }


    return (
        <>
            <Contenedor autorizado={[Permisos().empresaEdit]} rutas={['Inicio', 'Empresas', 'Configuración']}
                altura={'85vh'} spiner={false} anchoImagen={35}
                mensajeCarga={'Cargando Datos de Configuración...'}
                cargar={cargar}
                error={error} mensajeError={mensaje}
                titulo={'Colores de la Tarjeta'}
                header={<>
                    <div className="row mb-3">
                        <div className="col-xl-3 col-lg-3 col-8">Inicio Color Encabezado</div>
                        <div className="col-xl-1 col-lg-1 col-2" style={{ backgroundColor: definirColor(agregar.colorEncabezadoStart), minWidth: tam, minHeight: tam }}></div>
                        <div className="col-xl-3 col-lg-3 col-2">
                            <SelectColor
                                titulo={'Seleccionar Color del Encabezado'}
                                funcion={establecerColor}
                                dato={'colorEncabezadoStart'}
                                colorIcon={definirColor(agregar.colorEncabezadoStart)}

                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-xl-3 col-lg-3 col-8">Fin Color Encabezado</div>
                        <div className="col-xl-1 col-lg-1 col-2" style={{ backgroundColor: definirColor(agregar.colorEncabezadoEnd), minWidth: tam, minHeight: tam }}></div>
                        <div className="col-xl-3 col-lg-3 col-2">
                            <SelectColor
                                titulo={'Seleccionar Color del Encabezado'}
                                funcion={establecerColor}
                                dato={'colorEncabezadoEnd'}
                                colorIcon={definirColor(agregar.colorEncabezadoEnd)}

                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-xl-3 col-lg-3 col-8">Color del Texto en el Encabezado</div>
                        <div className="col-xl-1 col-lg-1 col-2" style={{ backgroundColor: definirColor(agregar.textoEncabezado), minWidth: tam, minHeight: tam }}></div>
                        <div className="col-xl-3 col-lg-3 col-2">
                            <SelectColor
                                titulo={'Seleccionar Color del texto del Encabezado'}
                                funcion={establecerColor}
                                dato={'textoEncabezado'}
                                colorIcon={definirColor(agregar.textoEncabezado)}
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-xl-3 col-lg-3 col-8">Color de Inicio Fondo Contenido de la Tarjeta</div>
                        <div className="col-xl-1 col-lg-1 col-2" style={{ backgroundColor: definirColor(agregar.colorTarjetaStart), minWidth: tam, minHeight: tam }}></div>
                        <div className="col-xl-3 col-lg-3 col-2">
                            <SelectColor
                                titulo={'Seleccionar Color de Inicio Fondo de la Tarjeta'}
                                funcion={establecerColor}
                                dato={'colorTarjetaStart'}
                                colorIcon={definirColor(agregar.colorTarjetaStart)}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-xl-3 col-lg-3 col-8">Color Final Fondo Contenido de la Tarjeta</div>
                        <div className="col-xl-1 col-lg-1 col-2" style={{ backgroundColor: definirColor(agregar.colorTarjetaEnd), minWidth: tam, minHeight: tam }}></div>
                        <div className="col-xl-3 col-lg-3 col-2">
                            <SelectColor
                                titulo={'Seleccionar Color Final Fondo de la Tarjeta'}
                                funcion={establecerColor}
                                dato={'colorTarjetaEnd'}
                                colorIcon={definirColor(agregar.colorTarjetaEnd)}
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-xl-3 col-lg-3 col-8">Color Texto de la Tarjeta</div>
                        <div className="col-xl-1 col-lg-1 col-2" style={{ backgroundColor: definirColor(agregar.textoTarjeta), minWidth: tam, minHeight: tam }}></div>
                        <div className="col-xl-3 col-lg-3 col-2">
                            <SelectColor
                                titulo={'Seleccionar Color del Texto de la Tarjeta'}
                                funcion={establecerColor}
                                dato={'textoTarjeta'}
                                colorIcon={definirColor(agregar.textoTarjeta)}
                            />
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-xl-3 col-lg-3 col-8"></div>
                        <div className="col-xl-1 col-lg-1 col-2">
                            <Boton titulo={"Guardar"} tipo={'principal'} onClick={() => enviar(agregar)} />
                        </div>
                        <div className="col-xl-3 col-lg-3 col-2">

                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-xl-10 col-lg-10 col-sm-10 col-12">
                            <label>Establecer Imagen Pie de Página (Se recomienda una imagen de 133px * 36px) </label>
                            <input type={'file'} className='form-control' onChange={(e) => cambiarFormulario(e, editar, setEditar, setArchivo)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-sm-9"></div>
                        <div className="col-xl-3 col-lg-3 col-sm-3">
                            <Boton tipo={'primary'} titulo={'Establecer'} onClick={() => enviarImagen()} />
                            </div>
                    </div>


                </>}


            />
        </>

    )
}
