import { SketchPicker } from "react-color";
import { useState } from "react";
import Modales from "infortech_modules/Componentes/Modales";
import Boton from "infortech_modules/Componentes/Boton";
import { IoIosColorPalette } from "react-icons/io";
export default function SelectColor({ titulo, funcion, dato, colorIcon }) {
    const [sketchPickerColor, setSketchPickerColor] = useState({ r: "241", g: "112", b: "19", a: "1" });



    const { r, g, b, a } = sketchPickerColor;
    const [modal, setModal] = useState(false);
    const open = () => {
        setModal(true);
    }

    const closed = () => {
        setModal(false)
    }

    const ejecutar = () => {
        funcion(sketchPickerColor, dato);
        closed();
    }


    return (
        <>
            <Boton tipo={'icono'} Icono={IoIosColorPalette} titulo={'Seleccionar Color'} sizeIcon={36} onClick={() => open()} colorIcon={colorIcon} />
            <Modales
                titulo={titulo}
                size={'sm'}
                show={modal}
                onHide={closed}
                body={<>
                    <div
                        className="App"
                        style={{ display: "flex", justifyContent: "space-around" }}
                    >
                        <div className="sketchpicker">

                            {/* Div to display the color  */}
                            <div className="mb-3"
                                style={{
                                    backgroundColor: `rgba(${r},${g},${b},${a})`,
                                    width: 100,
                                    height: 50,
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    display: 'block',
                                    border: "2px solid white",
                                }}
                            ></div>
                            {/* Sketch Picker from react-color and handling color on onChange event */}
                            <SketchPicker
                                onChange={(color) => {
                                    setSketchPickerColor(color.rgb);
                                }}
                                color={sketchPickerColor}
                            />
                        </div>
                    </div>

                </>}
                footer={<>
                    <Boton titulo={'Cerrar'} tipo={'secondary'} onClick={() => closed()} />
                    <Boton titulo={'Grabar Color'} tipo={'primary'} onClick={() => ejecutar()} />
                </>}

            />
        </>
    )
};
