import { useEffect, useState } from "react";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Get from "infortech_modules/Crud/Get";
import { Autorizado, usuario } from "infortech_modules/Constantes/Usuarios";
import Permisos from "infortech_modules/Constantes/Usuarios";
import ModalAdd from "infortech_modules/Modales/ModalAdd";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import { datosConsultarHistorial, duplicate, imagenes } from "infortech_modules/Constantes/Constantes";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import ModalEdit from "infortech_modules/Modales/ModalEdit";
import ModalDelete from "infortech_modules/Modales/ModalDelete";
import Modales from "infortech_modules/Componentes/Modales";
import Boton from "infortech_modules/Componentes/Boton";


export default function Servicios({ iniciar }) {
    const datosEditar = [];
    useEffect(() => {
        principal()
        // eslint-disable-next-line
    }, [])
    const [cargar, setCagar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('')
    const [Servicios, setServicios] = useState([]);
    const [agregar, setAgregar] = useState({
        titulo: '', nom: '', img: '', emp: usuario().emp
    })
    const [editar, setEditar] = useState({
        id: "", titulo: '', nom: '', img: '', emp: ""
    })
    const [img, setImg] = useState(undefined);
    const [imgEditar, setImgEditar] = useState(undefined);
    const [imagen, setImagen] = useState('');
    const [modalImagen, setModalImagen] = useState(false);
    const [titulo, setTitulo] = useState('');

    const principal = (est) => {
        est && setCagar(true);
        if (Autorizado([Permisos().servicios])) {
            Get({
                url: '/get', key: '2', id: usuario().emp, iniciar: iniciar
            }).then((r) => {
                setServicios(r);
                setTimeout(() => {
                    setCagar(false);
                }, 500);
            }).catch((f) => {
                setError(true);
                setTimeout(() => {
                    setMensaje(f);
                    setCagar(false)
                }, 500);
            })

        } else {
            setError(true)
            setTimeout(() => {
                setCagar(false);
                setMensaje('(Error 403 - Prohibido): No esta autorizado para ver esta pagina')
            }, 600);
        }

    }

    const cambiar = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const archivo = e.target.files[0];
            if (archivo.type.includes("image")) {
                setImg(archivo)
            }
        } else {
            setAgregar({
                ...agregar,
                [e.target.name]: e.target.value
            })
        }
    }

    const cambiarEditar = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const archivo = e.target.files[0];
            if (archivo.type.includes("image")) {
                setImgEditar(archivo)
            }
        } else {
            setEditar({
                ...editar,
                [e.target.name]: e.target.value
            })
        }
    }

    const inicializarDatos = (datos) => {
        setEditar(datos);
    }

    const abrirImagen = (data, titulo) => {
        setImagen(data);
        setTitulo(titulo);
        setModalImagen(true);
    }

    const cerrarImagen = () => {
        setImagen('');
        setTitulo('');
        setModalImagen(false)    
    }

    const formulario = [
        [CrearInput({ type: 'text', name: 'titulo', value: agregar.titulo, placeholder: 'Titulo' })],
        [CrearInput({ type: 'textarea', name: 'nom', value: agregar.nom, placeholder: 'Descripción' })],
        [CrearInput({ type: 'file', name: 'img', placeholder: 'Foto del Servicio' })]
    ]

    const formularioEditar = [
        [CrearInput({ type: 'text', name: 'titulo', value: editar.titulo, placeholder: 'Titulo' })],
        [CrearInput({ type: 'textarea', name: 'nom', value: editar.nom, placeholder: 'Descripción' })],
        [CrearInput({ type: 'file', name: 'img', placeholder: 'Foto del Servicio' })]
    ]

    const row = Servicios.map((v, i) => {
        datosEditar[i] = { id: v.id, titulo: v.titulo, nom: v.nom, img: v.img, emp: v.emp }
        return (
            <tr key={i}>
                {
                    CreaarFila([i + 1, v.titulo, v.nom, v.img !== "" ?<img onClick={() => abrirImagen(imagenes.servicios + v.img, v.titulo)} src={imagenes.servicios + v.img} className={'img-vista'} alt={`Imagen de ${v.titulo}`} />:""], "col", [""], "text-center")
                }
                <td className="text-center">
                    {
                        Autorizado([Permisos().serviciosEdit]) &&
                        <ModalEdit
                            modalTitulo={'Editar Servicio: ' + v.titulo}
                            cargaAnchoImagen={50} cargaAltura={100}
                            formulario={formularioEditar} onChange={cambiarEditar} inicializarDatos={()=>inicializarDatos(datosEditar[i])}
                            comprobarTextoVacio={[editar.nom, editar.img]} mensajeTextoVacio={['Digite un nombre para el Servicio', 'Digite el titulo del Servicio']}
                            duplicados={duplicate(true, [editar.titulo], ['titulo'], ['Un  Servicio con este título ya ha sido registrado'], [`and emp = '${usuario().emp}' and id != '${v.id}' `], true)}
                            mensajeLoadingPromesa={'Actualizando Servicio'} mensajePromesa={'Servicio Actualizado'}
                            caso={"ServiciosEdit"} 
                            url={'/put'} editar={editar} id = {`id = ${v.id}`} tabla={'servicios'}
                            recargar={false} iniciar={iniciar} principal={principal}
                            archivo={imgEditar} nombreArchivo={'photo'} rutaArchivo={'/Upload/servicios.php'}

                        />
                    }
                    &nbsp;  &nbsp; 
                    {
                        Autorizado([Permisos().serviciosDelete]) &&
                            <ModalDelete
                            modalTitulo={'Eliminar Servicio: '+v.titulo}
                            mensajeEliminar={<>¿Está seguro de eliminar el Servicio <strong>{v.titulo}</strong>?</>}
                            url={'/del'} tabla={'Servicios'} caso={"ServiciosDelete"} condicionEliminar={`id = ${v.id}`}
                            mensajeLoadingPromesa={'Eliminado Servicio'} mensajePromesa={'Servicio Eliminado'}
                            consultarHistorial={datosConsultarHistorial(false,['usuarios_servicios'], ['user'],['Hay usuarios asociados a este Servicio'],[`and est = '1'`] )}
                            valorEliminar={v.id} recargar={false} principal={principal} iniciar={iniciar}
                            />
                        
                    }
                </td>
            </tr>
        )
    })

    return (
        <>
            <Contenedor
                altura={'85vh'} spiner={false} anchoImagen={'35'} autorizado={Permisos().servicios}
                cargar={cargar} error={error} mensajeError={mensaje} mensajeCarga={"Cargando Tabla de Servicios"} classNameMensaje={'fs-6'}
                claseNameTextError={'fs-6'} colorIconError={'white'}
                titulo={'Servicios'}
                rutas={['Inicio', 'Servicios']}
                botonAdd={
                    Autorizado([Permisos().serviciosAdd]) && (
                        <ModalAdd
                            modalTitulo={'Agregar Servicios'}
                            modalSize={'lg'}
                            formulario={formulario} onChange={cambiar} small={true}
                            nombreArchivo={'photo'} archivo={img} rutaArchivo={'/Upload/servicios.php'}
                            tabla={'servicios'}
                            url={'/post'}
                            comprobarTextoVacio={[agregar.titulo, agregar.nom]} mensajeTextoVacio={['Debe escribir un título para el Servicio', 'Escriba la descripción para el Servicio']}
                            mensajeLoadingPromesa={'Registrando Servicio...'} mensajePromesa={'Servicio Agregado Correctamente'}
                            agregar={agregar} duplicados={duplicate(true, [agregar.titulo], ['titulo'], ['Un  Servicio con este título ya ha sido registrado'], [`and emp = '${usuario().emp}'`])}
                            caso={"ServiciosAdd"} recargar={false} principal={principal} iniciar={iniciar}
                        />
                    )
                }
                tablaEncabezado={['No.', 'Titulo', 'Descripción', 'Imagen', "Acción"]} tablaFilas={row} tablaNombre={'tps'}
                claseTablaEncabezado={'text-center'}
            />
            <Modales
            show={modalImagen}
            onHide={cerrarImagen}
            titulo={titulo}
            body={
                <div><img className="img-amp" src={imagen} alt={'Imagen ampliada del Servicio'} /></div>
            }
            footer={<Boton tipo={'secondary'} titulo={'Cerrar'} onClick={() => cerrarImagen()} />}
            />

        </>
    )
};
